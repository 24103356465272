import React from 'react';
import '../../assets/css/storyconnections.css';
import { Form, ListGroup} from "react-bootstrap";
import StoryChoiceConnections from "./StoryChoiceConnections";


class StoryStepConnections extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            idStep: props.stepData.idStep,
            stepOrder: props.stepData.stepOrder,
            descriptionStep: props.stepData.descriptionStep,
            isCriticStep: props.stepData.isCriticStep,
            isStartStep: props.stepData.isStartStep,
            isEndStep: props.stepData.isEndStep,
            idStory: props.stepData.idStory,
            idUser: this.account.idUser,
            nameStep: props.stepData.nameStep,
            choices: props.stepData.choices,
        }
    }

    render() {
        return (
            <ListGroup className={"triggers-list ml-3 mr-3 mb-3"}>
                <b><Form.Label className={"mt-3"}>Choix à lier</Form.Label></b>
                {this.state.choices.map((choice, key) => (
                    <StoryChoiceConnections key={key} choiceData={choice} idStory={this.state.idStory}/>
                ))}

            </ListGroup>

        )
    }
}

export default StoryStepConnections;
