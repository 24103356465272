import React from 'react';
import * as Controller from '../../controllers/groups_controller';
import '../../assets/css/groups.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Spinner} from "react-bootstrap";
import GroupCard from "./GroupCard";
import PlusGroup from "./PlusGroup";

class Groups extends React.Component {

    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            isLoaded: false,
            groups: [],
        }
    }

    async componentDidMount(): void {
        Controller.getAllOwnedGroups(this.state.account.idUser)
            .then(data => {
                this.setState({groups: data, isLoaded: true});
            })
    }

    render() {
        if (this.state.isLoaded === false) {
             return (
                 <Container style={{justifyContent: 'center', alignItems: 'center', height: '100%', display: 'flex', marginTop: 20}}>
                     <Spinner animation="border" role="status" style={{color: '#E94145'}}>
                         <span className="sr-only">Loading...</span>
                     </Spinner>
                 </Container>
             )
        } else {
            return (
                <Container className="container-groupcard">
                    <Row>
                        {this.state.groups.map((group, key) => (
                            <Col key={key} md={"auto"} className={"mt-4"}><GroupCard key={key} groupdata={group} /></Col>
                        ))}
                        <Col key={"newGroup-col"} md={"auto"} className={"newGroup-col mt-4"}><PlusGroup title={"Ajouter un groupe"} /></Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default Groups;
