import React from 'react';
import * as Controller from '../../controllers/indicators_controller';
import '../../assets/css/storyindicators.css';
import StoryNavigation from "./StoryNavigation";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import PlusIndicator from "./PlusIndicator";
import IndicatorCard from "./IndicatorCard";

class StoryIndicators extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            idStory : props.match.params.idStory,
            idUser: this.account.idUser,
            indicators: []
        }

    }

    componentDidMount(): void {
        Controller.getIndicators(this.state.idStory)
            .then(indicators => {
                this.setState({indicators: indicators});
            })
    }

    handleIndicatorSave = (indicator) => {

    }

    render() {
        return (
            <>
                <StoryNavigation key={this.state.idUser} indicatorsTab idStory={this.state.idStory} idUser={this.state.idUser}/>
                <Container className="container-storycard">
                    <h3 className={"mt-3"}>Liste des indicateurs</h3>
                    <Row className={"ml-2"}>
                        <Col className={"mt-4"}>
                            <Accordion className={"indicators"} defaultActiveKey="0">

                                {this.state.indicators.map((indicator, key) => (
                                    <IndicatorCard key={key} indicatorData={indicator} handleSaveClick={this.handleIndicatorSave}/>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                    <Col key={"plus-indicator-col"} md={"auto"}
                         className={"plus-indicator-col mt-4"}><PlusIndicator idStory={this.state.idStory} idUser={this.state.idUser}/></Col>
                </Container>
            </>
        )
    }
}

export default StoryIndicators;
