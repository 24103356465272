import React from 'react';
import {Card, Button, ListGroup, Accordion, InputGroup, Form, Alert} from 'react-bootstrap';
import '../../assets/css/general.css';
import '../../assets/css/triggers.css';
import Col from "react-bootstrap/Col";
import IndicatorTrigger from "./IndicatorTrigger";
import PlusTrigger from "./PlusTrigger";
import * as IndicatorsController from "../../controllers/indicators_controller";
import * as StepsController from '../../controllers/steps_controller';

class IndicatorCard extends React.Component {

    constructor(props) {
        super(props);

        var ls = require('local-storage');
        this.account = ls("account");
        this.DEFAULT_INDICATOR_NAME = "Indicateur non nommé";
        this.state = {
            idIndicator: props.indicatorData.idIndicator,
            nameIndicator: props.indicatorData.nameIndicator,
            defaultValueIndicator: props.indicatorData.defaultValueIndicator,
            storyIdStory: props.indicatorData.storyIdStory,
            imageIndicator: props.indicatorData.imageIndicator,
            triggers: props.indicatorData.triggers,
            isTriggerSaved: false,
            allSteps: []
        };

    }

    componentDidMount(): void {
        StepsController.getSteps(this.state.storyIdStory, this.account.idUser)
            .then((steps) => {
                this.setState({allSteps: steps});
            })
    }

    handleChange = event => {

        let {name, value} = event.target;
        if (name === "defaultValueIndicator") {
            value = parseInt(value);
        }
        this.setState({[name]: value})
    }

    handleNumberChange = event => {
        let {name, value, min, max} = event.target;
        //value = parseInt(value);
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        this.setState({[name]: value})
    };


    save = () => {
        this.setState({isTriggerSaved: true})
        //this.props.handleSaveClick(this.state);
        let indicatorCopy = {...this.state};
        if (indicatorCopy.nameIndicator === "")
            indicatorCopy.nameIndicator = this.DEFAULT_INDICATOR_NAME;
        if (isNaN(indicatorCopy.defaultValueIndicator))
            indicatorCopy.defaultValueIndicator = 0;

        IndicatorsController.updateIndicator(indicatorCopy);
    }

    render() {
        let triggerSavedAlert;
        let canAddTrigger;

        if (this.state.allSteps.length <= 0) {
            canAddTrigger =
                <Alert key={"lol"} variant={"info"} className={"add-trigger-info-alert"}>
                    L'histoire doit avoir au moins une étape pour pouvoir créer un événement.
                </Alert>
        } else {
            canAddTrigger = <PlusTrigger idStory={this.state.storyIdStory} idIndicator={this.state.idIndicator}/>
        }

        if (this.state.isTriggerSaved) {
            triggerSavedAlert =
                <Alert key={"lol"} variant={"success"} className={"indicator-saved-alert"}>
                    L'indicateur a été sauvegardé.
                </Alert>
        } else
            triggerSavedAlert = null;

        return (
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={this.state.idIndicator}>
                    <InputGroup>
                        {/*<IndicatorCross RedirectURL={"/archive/story/"} elementId={this.state.idStory} title={"Supprimer Indicateur"} text={"Voulez-vous vraiment supprimer cet indicateur ?"}/>*/}
                        <label className={"indicator-label"} htmlFor="basic-url"><b>{this.state.nameIndicator}</b></label>
                        <Button className={"indicator-modification-button"} variant="danger">Modifier</Button>
                    </InputGroup>
                </Accordion.Toggle>
                <Accordion.Collapse className={"mt-3"} eventKey={this.state.idIndicator}>
                    <Form>
                        <Form.Row className={"ml-2 mt-2"}>

                            <Form.Group as={Col} md="6" controlId={"formGridDefaultValue-" + this.state.idIndicator}>
                                Nom indicateur
                                <Form.Control
                                    name={"nameIndicator"}
                                    type="text"
                                    value={this.state.nameIndicator == this.DEFAULT_INDICATOR_NAME ? "" : this.state.nameIndicator}
                                    placeholder={this.DEFAULT_INDICATOR_NAME}
                                    onChange={this.handleChange}
                                    maxLength="40"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="2" controlId={"formGridDefaultValue-" + this.state.idIndicator}>
                                Valeur par défaut
                                <Form.Control
                                    name={"defaultValueIndicator"}
                                    type="number"
                                    placeholder=""
                                    pattern="\d{1,5}"
                                    min={-99}
                                    max={99}
                                    value={this.state.defaultValueIndicator}
                                    onChange={this.handleNumberChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <ListGroup className={"triggers-list ml-3 mr-3 mb-3"}>
                            <Form.Label className={"mt-1"}>Événements:</Form.Label>
                            {this.state.triggers.map((trigger, key) => (
                                <ListGroup.Item key={key} className={"trigger-item"}>
                                    <IndicatorTrigger
                                        key={key}
                                        nameIndicator={this.state.nameIndicator}
                                        triggerData={trigger}/>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        {canAddTrigger}
                        <Col key={"SaveButton"} className={"save-button-col"}>
                            <Button className={"indicator-save-button mb-5"} variant="danger"
                                    onClick={this.save}>Sauvegarder</Button>
                        </Col>
                        {triggerSavedAlert}
                    </Form>
                </Accordion.Collapse>
            </Card>

        )
    }
}

export default IndicatorCard;
