import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Card} from "react-bootstrap";
import * as Controller from "../controllers/stories_controller";

const Cross = (props) => {
    const {
        className,
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const archive = () => {
        //console.log("archive !");
        let idStory = props.idStory;
        let idUser = props.idUser;
        Controller.setArchived(idStory, 1, idUser)
            .then(() => {
                window.location.reload();
            });
    }


    return (
        <div>
            <Card.Text className={"storycard-cross mb-0"} onClick={toggle}>
                <img className={"cross-logo"}
                     src={window.location.origin + '/images/GUI/cross.svg'}
                     alt="Logo"
                />
            </Card.Text>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
                <ModalBody>
                    {props.text}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger"
                            onClick={archive}>Valider
                    </Button>
                    {' '}
                    <Button color="secondary" onClick={toggle}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Cross;
