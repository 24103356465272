import React from 'react';
import * as Controller from '../../controllers/choices_controller';
import '../../assets/css/storysteps.css';
import '../../assets/css/storystep.css';
import '../../assets/css/storychoices.css';
import {Container, Accordion} from "react-bootstrap";
import StoryChoice from "./StoryChoice";
import PlusChoice from "./PlusChoice";
import {Link} from "react-router-dom";

class StoryChoices extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.idStory = props.idStory;
        this.idStep = props.idStep;
        this.state = {
            choices: []
        }
        //console.log("idStep: " + this.idStep + " - idStory: " + this.idStory)
    }

    componentDidMount(): void {
        Controller.getChoices(this.idStep)
            .then(choices => {
                // console.log(choices);
                this.setState({choices: choices});
            })
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    }


    render() {
        return (
            <Container className="container-choices step-tabs-container">
                <Accordion className={"choices-accordion"} defaultActiveKey="">
                    {this.state.choices.map((choice, key) => (
                        <StoryChoice choiceData={choice} key={key} idStory={this.idStory}/>
                    ))}
                    <Link to={"/story/" + this.idStory + "/step/" + this.idStep + "/choices"}>
                        <PlusChoice idStep={this.idStep} idStory={this.idStory}/>
                    </Link>
                </Accordion>
            </Container>
        )
    }
}

export default StoryChoices;
