import React from 'react';
import * as Controller from '../../controllers/choices_controller';
import '../../assets/css/storysteps.css';
import '../../assets/css/storystep.css';
import '../../assets/css/storychoices.css';
import {Button, Card, InputGroup, Accordion, Form, Table, Alert} from "react-bootstrap";
import ChoiceSetValueButton from "./ChoiceSetValueButton";
import {Link} from "react-router-dom";

class StoryChoice extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.DEFAULT_CHOICE_NAME = "Choix non nommé";
        this.DEFAULT_CHOICE_DESCRIPTION = "Description par défaut";
        this.state = {
            idChoice: props.choiceData.idChoice,
            idStory: props.idStory,
            descriptionChoice: props.choiceData.descriptionChoice,
            nameChoice: props.choiceData.nameChoice,
            idStep: props.choiceData.idStep,
            setvalues: props.choiceData.setvalues,
            choiceSaved: false,
        }
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    }

    handleSaveButtonClick = () => {
        this.setState({choiceSaved: false});
        let copyChoice = {...this.state};
        if (copyChoice.nameChoice === "")
            copyChoice.nameChoice = this.DEFAULT_CHOICE_NAME;

        Controller.updateChoice(copyChoice)
            .then(() => {
                this.setState({choiceSaved: true});
            })
    }

    handleSetValueClick = () => {
        let newSetValues = [this.state.setvalues][0];
        this.setState({setvalues: newSetValues});
    }


    render() {
        let ChoiceSavedAlert;

        if (this.state.choiceSaved) {
            ChoiceSavedAlert =
                <Alert key={"lol"} variant={"success"} className={"mt-5 saved-alert"}>
                    Le choix a été sauvegardé
                </Alert>
        }

        return (
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={this.state.idChoice}>
                    <InputGroup>
                        {/*<IndicatorCross RedirectURL={"/archive/story/"} elementId={this.state.idStory} title={"Supprimer Indicateur"} text={"Voulez-vous vraiment supprimer cet indicateur ?"}/>*/}
                        <label className={"choice-label"}
                               htmlFor="basic-url">{this.state.nameChoice}</label>
                        <Button className={"choice-modification-button"}
                                variant="danger">Modifier</Button>
                    </InputGroup>
                </Accordion.Toggle>
                <Accordion.Collapse className={"choice-tab-content"} eventKey={this.state.idChoice}>
                    <Form className={"ml-3"}>
                        <InputGroup className={"choice-name-input mt-4"}>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-default">Nom choix</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                name={"nameChoice"}
                                type="text"
                                value={this.state.nameChoice == this.DEFAULT_CHOICE_NAME ? "" : this.state.nameChoice}
                                placeholder={this.state.nameChoice == this.DEFAULT_CHOICE_NAME ? this.state.nameChoice : ""}
                                onChange={this.handleChange}
                                maxLength="60"
                            />
                        </InputGroup>
                        <Form.Label className={"mt-2"}>Texte du choix</Form.Label>
                        <Form.Control
                            className={"choice-description mb-3"}
                            name={"descriptionChoice"}
                            as="textarea"
                            rows="5"
                            value={this.state.descriptionChoice == this.DEFAULT_CHOICE_DESCRIPTION ? "" : this.state.descriptionChoice}
                            placeholder={this.state.descriptionChoice == this.DEFAULT_CHOICE_DESCRIPTION ? this.state.descriptionChoice : ""}
                            onChange={this.handleChange}
                            maxLength="1000"
                        />
                        <Form.Label className={"mt-1"}>Impacts sur les indicateurs</Form.Label>
                        <Table responsive className={"choice-impact-table"}>

                            <tbody>
                            {this.state.setvalues.map((setValue, key) => (
                                <tr key={key}>
                                    <td>{setValue.indicatorName}</td>
                                    <td><ChoiceSetValueButton updateSetValueClick={this.handleSetValueClick}
                                                              setValueData={this.state.setvalues[key]} value={"-"}/>
                                    </td>
                                    <td>{setValue.changedValueSetValue > 0 ? "+" + setValue.changedValueSetValue : setValue.changedValueSetValue}</td>
                                    <td><ChoiceSetValueButton updateSetValueClick={this.handleSetValueClick}
                                                              setValueData={this.state.setvalues[key]} value={"+"}/>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Link to={"/story/" + this.state.idStory + "/step/" + this.state.idStep + "/choices"}>
                            <Button className={"step-save-button mb-5"} variant="danger"
                                    onClick={this.handleSaveButtonClick}>Sauvegarder</Button>
                        </Link>
                        {ChoiceSavedAlert}
                    </Form>
                </Accordion.Collapse>
            </Card>

        )
    }
}

export default StoryChoice;
