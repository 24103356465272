import React from 'react';
import {Navbar,Nav,NavDropdown} from 'react-bootstrap';
import '../assets/css/general.css';
import '../assets/css/topnav.css';
import {
    Link
} from "react-router-dom";



class TopNavigation extends React.Component {

    constructor(props) {
        super(props);

        var ls = require("local-storage");

        this.state = {
            account: ls("account"),
        }
    }
    render() {
        return (
            <Navbar className="top-navbar" expand="lg">
                <Link to="/dashboard"><Navbar.Brand className="nav-link" href="/dashboard">Story Flow</Navbar.Brand></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto nav">
                        <Link to="/dashboard"><Nav.Link href="/stories">MES HISTOIRES</Nav.Link></Link>
                        <Link to="/groups"><Nav.Link href="/groups">GROUPES</Nav.Link></Link>
                        {this.state.account.roleIdRole === 3 ? (
                            <Link to="/administration"><Nav.Link href="/administration">ADMINISTRATION</Nav.Link></Link>
                        ) : null}
                        <NavDropdown title="PROFIL" id="basic-nav-dropdown">
                            {/*<Link to="/account"><NavDropdown.Item href="/account">Mon Profil</NavDropdown.Item></Link>*/}
                            {/*<NavDropdown.Divider />*/}
                            <NavDropdown.Item href={"/"} onClick={()=> {
                                this.props.onClick();}}
                            >Déconnexion</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default TopNavigation;
