import React from 'react';
import '../../assets/css/storyobjects.css';
import StoryNavigation from "./StoryNavigation";
import {Container, Col, Row, Card, Accordion, InputGroup, Form, Button, CardImg} from "react-bootstrap";

class StoryObjects extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.idStory = props.match.params.idStory;
        this.state = {}
    }

    render() {
        return (
            <>
                <StoryNavigation key={this.account.idUser} objectsTab idStory={this.idStory}/>
                <Container className="container-storycard">
                    <h3 className={"mt-3"}>Liste des objets</h3>
                    <Row className={"ml-2"}>
                        <Col className={"mt-4"}>
                            <Accordion className={"objects"}>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <InputGroup>
                                            <Form.Control
                                                className={"object-title-control"}
                                                name={"nameStory"}
                                                type="text"
                                                value={"Objet 1"}
                                            />
                                            <Button className={"object-load-button"} variant="danger">Charger
                                                Image</Button>
                                            <CardImg className={"object-image"} top={"true"} width="100%"
                                                     src={window.location.origin.toString() + '/images/background-story-0931.jpg'}
                                                     alt="Card image cap"/>
                                            <span className={"object-image-filename"}>object_image1.png</span>
                                        </InputGroup>
                                    </Accordion.Toggle>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <InputGroup>
                                            <Form.Control
                                                className={"object-title-control"}
                                                name={"nameStory"}
                                                type="text"
                                                value={"Objet 2"}
                                            />
                                            <Button className={"object-load-button"} variant="danger">Charger
                                                Image</Button>
                                            <CardImg className={"object-image"} top={"true"} width="100%"
                                                     src={window.location.origin.toString() + '/images/background-story-1583.jpg'}
                                                     alt="Card image cap"/>
                                            <span className={"object-image-filename"}>object_image2.png</span>
                                        </InputGroup>
                                    </Accordion.Toggle>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default StoryObjects;
