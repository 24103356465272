import React from 'react';
import * as Controller from '../../controllers/steps_controller';
import '../../assets/css/storysteps.css';
import '../../assets/css/storystep.css';
import StoryNavigation from "./StoryNavigation";
import {Container, Tab, Tabs, Button, Form, InputGroup, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import StoryChoices from "./StoryChoices";
import {Link} from "react-router-dom";

class StoryStep extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.idStory = props.match.params.idStory;
        this.idStep = props.match.params.idStep;
        this.DEFAULT_STEP_NAME = "Nom par défaut";
        this.DEFAULT_STEP_DESCRIPTION = "Description par défaut";
        let activetab = props.match.url.split("/")[5];

        this.state = {
            idStep: this.idStep,
            stepOrder: -1,
            descriptionStep: "",
            nameStep: "",
            isCriticStep: 0,
            isStartStep: 0,
            isEndStep: 0,
            idStory: this.idStory,
            idUser: this.account.idUser,
            choices: [],
            activeTab: activetab
        }
    }

    componentDidMount(): void {
        Controller.getStep(this.idStep, this.account.idUser)
            .then(step => {
                this.setState({
                    idStep: step.idStep,
                    stepOrder: step.stepOrder,
                    descriptionStep: step.descriptionStep,
                    nameStep: step.nameStep,
                    isCriticStep: step.isCriticStep,
                    isStartStep: step.isStartStep,
                    isEndStep: step.isEndStep,
                    idStory: step.idStory,
                    choices: step.choices,
                });
            })
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    }

    handleCheckBox = event => {
        const {name, checked} = event.target;
        let numericBool = checked ? 1 : 0;
        if (name === "isStartStep" && numericBool)
            this.setState({isEndStep: 0})

        if (name === "isEndStep" && numericBool)
            this.setState({isStartStep: 0})

        this.setState({[name]: numericBool})
    }


    handleStepGeneralSave = () => {
        //console.log("Update the step: " + this.state.idStep);
        let step_representation = {...this.state};
        step_representation.choices = [];
        if (step_representation.nameStep === "")
            step_representation.nameStep = this.DEFAULT_STEP_NAME;

        Controller.updateStep(this.state.idStory, this.account.idUser, step_representation)
            .then((result) => {
                //console.log(result);
                window.location.reload();
            })
    }

    render() {
        return (
            <>
                <StoryNavigation key={this.state.idUser} stepsTab idStory={this.state.idStory}
                                 idUser={this.state.idUser}/>
                <Container className="container-storycard">
                    <Link to={"/story/" + this.idStory + "/steps"}>
                        <Button className={"back-step-button mt-4"} variant="danger">
                            <img className={"story-back-icon"}
                                 src={window.location.origin + '/images/GUI/left_arrow.svg'}
                                 alt="Logo"
                            />{" Retour"}
                        </Button>
                    </Link>
                    <Row className={"step-row ml-2 mt-4"}>
                        <Col>
                            <Tabs defaultActiveKey={this.state.activeTab} id="step-tab-id">
                                <Tab eventKey={"general"} title="Général" className={"step-tab"}>
                                    <Container className="container-storycard step-tabs-container">
                                        <InputGroup className={"step-name-input mt-4"}>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroup-sizing-default">Nom
                                                    étape</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name={"nameStep"}
                                                type="text"
                                                value={this.state.nameStep == this.DEFAULT_STEP_NAME ? "" : this.state.nameStep}
                                                placeHolder={this.DEFAULT_STEP_NAME}
                                                onChange={this.handleChange}
                                                maxLength="80"
                                            />
                                        </InputGroup>
                                        <Form.Label className={"mt-2"}>Texte d'étape</Form.Label>
                                        <Form.Control
                                            className={"step-description mb-3"}
                                            name={"descriptionStep"}
                                            as="textarea"
                                            rows="10"
                                            value={this.state.descriptionStep == this.DEFAULT_STEP_DESCRIPTION ? "" : this.state.descriptionStep}
                                            placeHolder={this.DEFAULT_STEP_DESCRIPTION}
                                            onChange={this.handleChange}
                                            maxLength="1000"
                                        />
                                        <Form.Group controlId="StepCheckBox" className={"step-checkboxes"}>
                                            <Form.Check type="checkbox" label="Étape de départ" id={"isStartStep"}
                                                        name={"isStartStep"}
                                                        checked={this.state.isStartStep ? "checked" : ""}
                                                        onChange={this.handleCheckBox}
                                            />
                                            <Form.Check type="checkbox" label="Étape de fin" id={"isEndStep"}
                                                        name={"isEndStep"}
                                                        checked={this.state.isEndStep ? "checked" : ""}
                                                        onChange={this.handleCheckBox}
                                            />
                                            {/*<Form.Check type="checkbox" label="Étape critique" id={"isCriticStep"}
                                                        name={"isCriticStep"}
                                                        checked={this.state.isCriticStep ? "checked" : ""}
                                                        onChange={this.handleCheckBox}
                                            />*/}
                                            <label className={"save-reminder-label"}>N'oubliez pas de sauvegarder avant
                                                de changer d'onglet</label>
                                        </Form.Group>
                                        <Button className={"step-save-button mb-3"} variant="danger"
                                                onClick={this.handleStepGeneralSave}>Sauvegarder</Button>
                                    </Container>
                                </Tab>
                                <Tab eventKey="choices" title={"Choix"}>
                                    <StoryChoices idStep={this.state.idStep} idStory={this.state.idStory}/>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                </Container>
            </>
        )
    }
}

export default StoryStep;
