import React, {Component, Fragment} from 'react';
import {Accordion, Badge, Card} from 'react-bootstrap';
import UsersCard from "./components/UsersCard";
import RequestsCard from "./components/RequestsCard";

class Index extends Component {
    render() {
        return (
            <Fragment>
                <Accordion defaultActiveKey="0" style={{marginTop: 50, marginLeft: 50, marginRight: 50, marginBottom: 50}}>
                    <UsersCard />
                    <RequestsCard />
                </Accordion>
            </Fragment>
        );
    }
}

export default Index;
