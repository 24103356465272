import React from 'react';
import {Card, Table, Form} from 'react-bootstrap';
/*import Cross from './Cross';*/
import GroupAddModal from './GroupAddModal'
import '../../assets/css/general.css';
import '../../assets/css/groupcard.css';
import GroupMember from "./GroupMember";

class GroupCard extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.groupdata;
        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            account:this.account
        }
    }

    render() {
        return (
            <Card className="text-center" style={{width: '19rem'}}>
                <Card.Body>
                    {/*<Cross RedirectURL={"/delete/group/"} elementId={this.data.idGroup} title={"Suppression Groupe"}
                           text={"Voulez-vous vraiment supprimer ce groupe ?"}/>*/}
                    <div className={"mb-4"}/>
                    <div className={"group-color mb-3"} style={{backgroundColor: this.data.colorGroup}}/>
                    <Card.Title>{this.data.nameGroup}</Card.Title>
                    <Card.Subtitle
                        className="mb-2 text-muted">{this.state.account.pseudoUser}</Card.Subtitle>
                    <Table className={"table-member"} responsive striped bordered hover size="sm">
                        <tbody>
                        {this.data.users.map(user => (
                            <GroupMember data={user} group={this.data.idGroup} />
                        ))}
                        </tbody>
                    </Table>
                    <GroupAddModal title={"Ajouter un utilisateur"} idGroup={this.data.idGroup}/>
                    {/*<a href={"/"}>*/}
                    {/*    <Form.Text className="text-muted mt-3">*/}
                    {/*        Copier l'url d'invitation*/}
                    {/*    </Form.Text>*/}
                    {/*</a>*/}
                </Card.Body>
            </Card>
        )
    }
}

export default GroupCard;
