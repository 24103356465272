import React from 'react';
import {Card} from "react-bootstrap";
import '../assets/css/plusStory.css';
import {Button} from "reactstrap";
import * as Controller from "../controllers/stories_controller";

const PlusStory = (props) => {

    const createStory = () => {
        //console.log("create story");
        let idUser = props.idUser;
        Controller.CreateStory(idUser)
            .then(id => {
                //window.location = '/Story/' + id + '/general';
                window.location.reload();
            });
    }

    return (

        <div className={"PlusStory-Container"}>
            <Button className={"plus-story-button button"} onClick={createStory}>
                <Card.Text className={"mb-0"}>
                    <img className={"plus-logo"}
                         src={window.location.origin + '/images/GUI/plus.png'}
                         alt="Logo"
                    />
                </Card.Text>
            </Button>
        </div>

    )
        ;
};

export default PlusStory;
