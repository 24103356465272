import React from 'react';
import {Card,Button, CardImg} from 'react-bootstrap';
import StoryCardTag from './StoryTagBadge';
import Cross from './Cross';
import '../assets/css/general.css';
import '../assets/css/storycard.css';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import * as Controller from '../controllers/stories_controller';
import {Link} from "react-router-dom";
import Moment from 'moment';

class StoryCard extends React.Component {

    constructor(props) {
        super(props);

        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            idStory:props.storydata.idStory,
            nameStory:props.storydata.nameStory,
            descriptionStory:props.storydata.descriptionStory,
            dateCreatedStory : props.storydata.dateCreatedStory,
            idUser:props.storydata.idUser,
            visible:props.storydata.visible,
            storyImage:props.storydata.storyImage,
            archived:props.storydata.archived,
            groups : props.storydata.groups,
            themes : props.storydata.themes
        };

        this.data = props.storydata;
    }

    restore() {
        // appel du back pour restaurer
        //this.data.archived = false;
        Controller.setArchived(this.state.idStory, 0, this.account.idUser);
        this.setState({archived : false});
    }

    render() {
        return (
            <Card className="text-center" style={{ width: '15rem' }}>
                <div className={"suppressed-cover"} style={{display: this.state.archived ? 'block' : 'none' }}>
                    <span className={"suppressed-title"}>Archivé</span>
                    <Button variant="light"  className="restore-button"  onClick={()=> {this.restore()}}>Restaurer</Button>
                    {/*<Button variant="light"  className="delete-button"  href={"/delete/story/" + this.state.idStory}>Supprimer</Button>*/}
                </div>
                <Card.Body>
                    <Cross RedirectURL={"/archive/story/"} idStory={this.state.idStory} idUser={this.account.idUser} title={"Archiver Histoire"} text={"Voulez-vous vraiment archiver cette histoire ?"}/>
                    <div className={"mb-4"}/>
                    <CardImg className={"card-image mb-3"} top={"true"} width="100%" src={window.location.origin.toString() + '/images/background-story-0931.jpg'} alt="Card image cap" />
                    <Card.Title>
                        {this.state.nameStory}<br/><span className={"invisible-label"} style={{display: this.state.visible ? 'none' : 'block' }}>Invisible aux lecteurs</span>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Crée le <br/>{Moment(this.state.dateCreatedStory).format('DD.MM.YYYY')}</Card.Subtitle>
                    <Container className={"container-tagBadge mb-3"}>
                        {this.state.themes.map((theme,key) => (
                                <Col key={key}><StoryCardTag  key={key} title={theme.nameTheme} color={theme.colorTheme}/></Col>
                        ))}
                        {this.state.groups.map((group,key) => (
                            <Col key={key}><StoryCardTag key={key} title={group.nameGroup} color={group.colorGroup}/></Col>
                        ))}
                    </Container>
                    <Link to={"/story/" + this.state.idStory + "/general"}>
                        <Button className="storycard-button">Modifier</Button>
                    </Link>

                </Card.Body>
            </Card>
        )
    }
}

export default StoryCard;
