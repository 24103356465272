import React from 'react';
import {Button} from "react-bootstrap";
import '../../assets/css/storysteps.css';
import * as Controller from '../../controllers/choices_controller';

const ChoiceSetValueButton = (props) => {

    const updateSetValue = () => {

        let newSetValue = [props.setValueData][0];
        if (props.value === "-") {
            newSetValue.changedValueSetValue -= 1;
        } else {
            newSetValue.changedValueSetValue += 1;
        }
        Controller.updateSetValue(newSetValue)
            .then(() => {
                props.updateSetValueClick();
            })
    }

    return (
        <Button variant="light" size="sm" onClick={updateSetValue}>
            {props.value}
        </Button>
    );
};

export default ChoiceSetValueButton;
