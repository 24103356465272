import React from 'react';
import {Card, Button} from "react-bootstrap";
import '../../assets/css/plusStory.css';
import * as Controller from "../../controllers/indicators_controller";

const PlusTrigger = (props) => {

    function createTrigger() {
        //console.log("create trigger for the indicator " + props.idIndicator + " int the story " + props.idStory);
        Controller.createTrigger(props.idStory, props.idIndicator)
            .then(id => {
                window.location.reload();
            });
    }

    return (

        <div className={"plus-trigger-Container"}>
            <Button className={"plus-trigger-button button"} onClick={createTrigger}>
                <Card.Text className={"mb-0"}>
                    <img className={"plus-logo-trigger"}
                         src={window.location.origin + '/images/GUI/plus.png'}
                         alt="Logo"
                    />
                </Card.Text>
            </Button>
        </div>

    );
};

export default PlusTrigger;
