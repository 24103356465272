import React from 'react';
import '../../assets/css/dashboard.css';
import StoryNavigation from "./StoryNavigation";
import * as ConnectionsController from '../../controllers/connections_controller';
import {Container, Accordion, Button, Card, Col, InputGroup, Row, Modal} from "react-bootstrap";
import StoryStepConnections from "./StoryStepConnections";
import {Link} from "react-router-dom";

class StoryConnections extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.idStory = props.match.params.idStory;
        this.state = {
            activeAccordion: props.match.params.stepOrder,
            idStory: props.match.params.idStory,
            idUser: this.account.idUser,
            steps: []
        }
    }

    componentDidMount(): void {
        ConnectionsController.getStepsChoicesConnections(this.state.idStory, this.state.idUser)
            .then(steps => {
                this.setState({steps: steps});
            })
    }

    render() {
        return (
            <>
                <StoryNavigation key={this.state.idUser} connectionsTab idStory={this.state.idStory}
                                 idUser={this.state.idUser}/>
                <Container className="container-storycard mb-5">
                    <h3 className={"mt-3"}>Liste des étapes</h3>
                    <Row className={"ml-2"}>
                        <Col className={"mt-4"}>
                            <Accordion className={"steps"} defaultActiveKey={this.state.activeAccordion}>
                                {this.state.steps.map((step, key) => (
                                    <Card key={key}>
                                        <Accordion.Toggle as={Card.Header} eventKey={step.stepOrder.toString()}>
                                            <InputGroup>
                                                {/*<IndicatorCross RedirectURL={"/archive/story/"}
                                                                elementId={step.idStep} title={"Supprimer étape"}
                                                                text={"Voulez-vous vraiment supprimer cette étape ?"/>*/}
                                                <label className={"step-order-label"}><b>{step.stepOrder} - </b></label>
                                                <label className={"step-label"}
                                                       htmlFor="basic-url"><b>{step.nameStep}</b>
                                                </label>
                                                <Link
                                                    to={"/story/" + this.state.idStory + "/connections/" + step.stepOrder}>
                                                    <Button className={"step-modification-button"}
                                                            variant="danger">
                                                        Lier
                                                    </Button>
                                                </Link>
                                            </InputGroup>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse className={"choice-tab-content"}
                                                            eventKey={step.stepOrder.toString()}>
                                            <StoryStepConnections key={key} stepData={step}/>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default StoryConnections;
