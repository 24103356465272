import React from 'react';
import {Card, Button} from "react-bootstrap";
import '../../assets/css/plusStory.css';
import * as Controller from "../../controllers/indicators_controller";

const PlusIndicator = (props) => {

    function createIndicator() {
        console.log("create indicators for the story " + props.idStory + " with the user " + props.idUser);
        Controller.createIndicator(props.idStory, props.idUser)
            .then(id => {
                window.location.reload();
            });
    }

    return (

        <div className={"plus-indicator-Container"}>
            <Button className={"plus-indicator-button button"} onClick={createIndicator}>
                <Card.Text className={"mb-0"}>
                    <img className={"plus-logo"}
                         src={window.location.origin + '/images/GUI/plus.png'}
                         alt="Logo"
                    />
                </Card.Text>
            </Button>
        </div>

    );
};

export default PlusIndicator;
