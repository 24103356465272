import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Card} from "react-bootstrap";
import * as Controller from "../../controllers/connections_controller";

const ConnectionsCross = (props) => {
    const {
        className,idStepChoice, idUser
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const suppress = () => {
        //console.log("delete stepchoice ID: " + idStepChoice + " from user ID: " + idUser);
        Controller.deleteStepChoice(idStepChoice,idUser)
            .then(() => {
                window.location.reload();
            });
    }


    return (
        <div className={"mr-3 mt-1"}>
            <Card.Text className={"storycard-cross mb-0"} onClick={toggle}>
                <img className={"cross-logo"}
                     src={window.location.origin + '/images/GUI/cross.svg'}
                     alt="Logo"
                />
            </Card.Text>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{"Supprimer le lien"}</ModalHeader>
                <ModalBody>
                    {"Voulez-vous vraiment supprimer ce lien ?"}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger"
                            onClick={suppress}>Valider
                    </Button>
                    {' '}
                    <Button color="secondary" onClick={toggle}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ConnectionsCross;
