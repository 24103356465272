import * as config from "../config";

export async function getPromotionRequests(idAdmin) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idAdmin", idAdmin);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
    };

    var myRequest = new Request(config.API_URL + '/api/promotions');

    let data = await (await (fetch(myRequest, myInit)
        .then(res => {
            if (res.ok)
                return res.json()
            else
                return [];
        })
        .catch(err => {
            console.log('Error getPromotionsRequests: ', err);
            return [];
        })
    ))

    return data;
}

export async function updatePromotion(promotionrequest) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
        body: promotionrequest,
    };

    var myRequest = new Request(config.API_URL + '/api/promotion');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return [];
            })
            .catch(err => {
                console.log('Error updatePromotion: ', err);
                return [];
            })
    ))

    return data;
}

export async function deleteRequests(idUser) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", parseInt(idUser));

    var myInit = {
        method: 'DELETE',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
    };

    var myRequest = new Request(config.API_URL + '/api/promotion');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return [];
            })
            .catch(err => {
                console.log('Error updatePromotion: ', err);
                return [];
            })
    ))

    return data;
}
