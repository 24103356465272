import * as config from '../config';

export async function getSteps(idStory, idUser) {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/steps');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function getStep(idStep, idUser) {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStep", idStep);
    //myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/stepid');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function createStep(idStory, idUser, stepOrder) {
    if (idUser === undefined) {
        return -1;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);
    myHeaders.append("stepOrder", stepOrder);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/step');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function updateStep(idStory, idUser, step) {

    let jsonStep = JSON.stringify(step);
    if (step === undefined || step === []) {
        return [];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);


    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body : jsonStep,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/step');
    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}