import React, {Component} from 'react';
import {Card, Form} from 'react-bootstrap';
import '../../assets/css/plusStory.css';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {GithubPicker} from 'react-color';
import {CreateGroup} from "../../controllers/groups_controller";

class PlusGroup extends Component {
    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            modal: false,
            displayColorPicker: false,
            error: "",
            name: null,
            password: null,
            color: "#E2E296",
        }
    }

    toggle = () => {this.setState({modal: !this.state.modal})}

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChangeColor = async (color) => {
        await this.setState({color: color.hex});
    }

    handleChange = async (event) => {
        const {name, value} = event.target;
        await this.setState({[name]: value});
    }

    handleSubmit = async () => {
        if (this.state.name.length > 45) {
            this.setState({error: "Le nom ne doit pas dépasser plus de 45 caractères !"});
        } else if (this.state.password !== null) {
            if (this.state.password.length > 45) {
                this.setState({error: "Le mot de passe ne doit pas dépasser plus de 45 caractères !"});
            }
        } else if (this.state.name === "") {
            this.setState({error: "Le nom est obligatoire !"});
        } else {
            const group = JSON.stringify({
                nameGroup: this.state.name,
                pwdGroup: this.state.password,
                colorGroup: this.state.color
            })

            const data = await CreateGroup(this.state.account.idUser, group);

            if (data === -1) {
                this.setState({error: "Il y a eut un problème lors de l'ajout du groupe"})
            } else {
                this.toggle();

                window.location.reload();
            }
        }
    }

    render() {
        const {
            className,
        } = this.props;

        const colors = ['#E2E296', '#B2D988', '#E2A684', '#F7B382', '#F9D987', '#85E2CE', '#85C1F2', '#B188EF', '#F285C1', '#F9858D'];

        return (
            <div className={"PlusStory-Container"}>
                <Button className={"plus-story-button button"} onClick={this.toggle}>
                    <Card.Text className={"mb-0"}>
                        <img className={"plus-logo"}
                             src={window.location.origin + '/images/GUI/plus.png'}
                             alt="Logo"
                        />
                    </Card.Text>
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={className}>
                    <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Nom du groupe</Form.Label>
                                <Form.Control required name="name" type="text" placeholder="Nom du groupe" onChange={this.handleChange} maxlength="12"/>
                            </Form.Group>

                            {/*<Form.Group controlId="formBasicPassword">
                                <Form.Label>Mot de passe du groupe</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Password" onChange={this.handleChange} />
                            </Form.Group>*/}

                            <Form.Group controlId="formBasicColor">
                                <Form.Label>Couleur du groupe</Form.Label>
                                <br></br>
                                <div style={{
                                    padding: '5px',
                                    background: '#fff',
                                    borderRadius: '1px',
                                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                    display: 'inline-block',
                                    cursor: 'pointer'
                                }} onClick={ this.handleClick }>
                                    <div style={{
                                        width: '36px',
                                        height: '14px',
                                        borderRadius: '2px',
                                        background: this.state.color,
                                    }} />
                                </div>

                                { this.state.displayColorPicker ?
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: '2',
                                    }}>
                                    <div style={{
                                        position: 'fixed',
                                        top: '0px',
                                        right: '0px',
                                        bottom: '0px',
                                        left: '0px',
                                    }} onClick={ this.handleClose }/>
                                        <GithubPicker name="color" width={100} color={ this.state.color } colors={colors} onChange={this.handleChangeColor} />
                                    </div>
                                : null }
                            </Form.Group>
                            {this.state.error.length !== 0 ? (
                                <Form.Group controlId="formBasicError" style={{justifyContent: 'center', alignItems: 'center'}}>
                                    <Form.Label style={{color: "red", fontWeight: 'bold'}}>{this.state.error}</Form.Label>
                                </Form.Group>
                            ) : ''}
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleSubmit}>Ajouter</Button>
                        <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default PlusGroup;
