import React, { Component } from 'react';
import {Card} from "react-bootstrap";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DeleteUserToGroup} from "../../controllers/groups_controller";

class DirectCross extends Component {
    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            modal: false,
            user: this.props.data,
        }
    }

    toggle = () => {
        this.setState({modal: !this.state.modal})
    }

    handleSubmit = async () => {
        const del = await DeleteUserToGroup(this.state.user.idUser, this.props.group, this.state.account.idUser);

        if (del === 200) {
            window.location.reload();
        }
    }

    render() {
        return (
            <React.Fragment>
                <a onClick={this.toggle}>
                    <Card.Text className={"storycard-directcross mb-0"}>
                        <img
                            className={"cross-logo"}
                            src={window.location.origin + '/images/GUI/cross.png'}
                            alt="Delete Logo"
                        />
                    </Card.Text>
                </a>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Suppression Membre</ModalHeader>
                    <ModalBody>
                        Voulez-vous vraiment supprimer ce membre ({this.state.user.mailUser}) du groupe ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleSubmit}>Valider</Button>
                        <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default DirectCross;
