import React from 'react';
import {Card,Button} from "react-bootstrap";
import '../../assets/css/storysteps.css';
import * as Controller from '../../controllers/steps_controller';

const PlusStep = (props) => {

    function createStep() {
        //console.log("create step for the story " + props.idStory + " with the user " + props.idUser + " at the steporder :" + props.stepOrder);

        Controller.createStep(props.idStory, props.idUser, props.stepOrder)
            .then(id => {
                window.location.reload();
            });
    }

    return (

        <div className={"PlusStep-Container"}>
            <Button className={"plus-story-button button"} onClick={createStep}>
                <Card.Text className={"mb-0"}>
                    <img className={"plus-logo"}
                         src={window.location.origin + '/images/GUI/plus.png'}
                         alt="Logo"
                    />
                </Card.Text>
            </Button>
        </div>

    );
};

export default PlusStep;
