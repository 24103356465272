import React, {Component, Fragment} from 'react';
import {Form} from "react-bootstrap";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {updateUser} from "../../../controllers/users_controller";
import {deleteRequests} from "../../../controllers/promotion_controller";

class SelectRole extends Component {
    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            modal: false,
            user: this.props.user,
            selectedValue: this.props.user.roleIdRole,
        }
    }

    handleChange = (e) => {
        this.setState({modal: !this.state.modal, selectedValue: e.target.value});
    }

    handleSubmit = async () => {
        let user = this.state.user;
        user['roleIdRole'] = parseInt(this.state.selectedValue);
        let update = await updateUser(user);

        if (update !== null) {
            if (this.state.selectedValue === 1) {
                deleteRequests(update.idUser);
            }

            window.location.reload();
        }
    }

    getRole = (role) => {
        switch(parseInt(role)) {
            case 2:
                return "Créateur";
                break;
            case 3:
                return "Super Admin";
                break;
            default:
                return "Lecteur";
        }
    }

    toggle = () => {
        if (this.state.modal === false) {
            this.setState({modal: !this.state.modal})
        } else {
            console.log(this.props.user.roleIdRole);
            this.setState({selectedValue: this.props.user.roleIdRole, modal: !this.state.modal})
        }
    }

    render() {
        return (
            <Fragment>
                <Form.Control id="role" as="select" defaultValue={this.state.user.roleIdRole} value={this.state.selectedValue} onChange={this.handleChange}>
                    <option value="1">Lecteur</option>
                    <option value="2">Créateur</option>
                    <option value="3">SuperAdmin</option>
                </Form.Control>

                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Confirmation de changement de rôle</ModalHeader>
                    <ModalBody>
                        <p>{this.state.user.lastNameUser} {this.state.user.firstNameUser} passera du role {this.getRole(this.state.user.roleIdRole)} à {this.getRole(this.state.selectedValue)}.</p>

                        <p>Confirmez-vous que vous voulez bien changer le rôle de cet utilisateur ?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleSubmit}>Valider</Button>
                        <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

export default SelectRole;
