import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Card, Form, Alert, Col, Row} from "react-bootstrap";
import {AddUserToGroup} from "../../controllers/groups_controller";

import {withRouter} from 'react-router-dom';

class GroupAddModal extends Component {
    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            modal: false,
            users: '',
            emailErrors: [],
            dbErrors: [],
            success: [],
        }
    }

    toggle = () => {
        if (this.state.modal === false) {
            this.setState({modal: !this.state.modal})
        } else {
            window.location.reload();
        }
    }

    handleChange = async (event) => {
        const {name, value} = event.target;
        await this.setState({[name]: value});
    }

    validate = (email) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    }

    handleSubmit = async () => {
        let emailErrors = [];
        let dbErrors = [];
        let success = [];

        const {users} = this.state;

        let emails = users.split(",");

        let adding;

        for (const email of emails) {
            let mail = email.replace(" ", "");
            const isValid = this.validate(mail);

            if (isValid) {
                adding = await AddUserToGroup(this.state.account.idUser, this.props.idGroup, mail);
                if (adding !== 200) {
                    dbErrors.push(mail);
                } else {
                    success.push(mail);
                }
                this.setState({users: ''})
            } else {
                emailErrors.push(mail);
            }
        }

        await this.setState({emailErrors: emailErrors, dbErrors: dbErrors, success: success})
    }

    render() {
        const {
            className,
        } = this.props;

        return (
            <div>
                <Card.Text className={"mb-0"} onClick={this.toggle}>
                    <Button variant="danger">
                        Ajouter
                    </Button>
                </Card.Text>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={className}>
                    <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Form.Group controlId="formBasicText">
                                <Form.Label>Utilisateur(s) à ajouté(s)</Form.Label>
                                <Form.Control type="text" name="users" placeholder="E-mail utilisateurs"
                                              onChange={this.handleChange}/>
                                <Form.Text className="text-muted">
                                    Ajout multiple avec une "," comme séparateur (john@gmail.com, doe@gmail.com)
                                </Form.Text>
                            </Form.Group>
                        </Form>
                        {this.state.success.length !== 0 ? (
                            <Alert variant="success">
                                <Col>
                                    {this.state.success.length === 1 ? (
                                        <Row>Cet e-mail a bien été ajouté :</Row>
                                    ) : (
                                        <Row>Ces e-mails ont bien été ajoutés :</Row>
                                    )}
                                    {this.state.success.map(success => (
                                        <Row>{success}</Row>
                                    ))}
                                </Col>
                            </Alert>
                        ) : null}
                        {this.state.emailErrors.length !== 0 || this.state.dbErrors.length !== 0 ? (
                            <Alert variant="warning">
                                {this.state.emailErrors.length !== 0 ? (
                                    <Col>
                                        {this.state.success.length === 1 ? (
                                            <Row>Cette entrées n'est pas un e-mail valide :</Row>
                                        ) : (
                                            <Row>Ces entrées ne sont pas des e-mails valides :</Row>
                                        )}
                                        {this.state.emailErrors.map(error => (
                                            <Row>{error}</Row>
                                        ))}
                                    </Col>
                                ) : null}
                                <br></br>
                                {this.state.dbErrors.length !== 0 ? (
                                    <Col>
                                        {this.state.success.length === 1 ? (
                                            <Row>Cet e-mail n'existe pas :</Row>
                                        ) : (
                                            <Row>Ces e-mails n'existent pas :</Row>
                                        )}
                                        {this.state.dbErrors.map(error => (
                                            <Row>{error}</Row>
                                        ))}
                                    </Col>
                                ) : null}
                            </Alert>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.handleSubmit}>Ajouter</Button>
                        <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withRouter(GroupAddModal);
