import React from 'react';
import {Card, Button} from "react-bootstrap";
import '../../assets/css/storysteps.css';
import * as Controller from '../../controllers/choices_controller';

const PlusChoice = (props) => {

    function createChoice() {
        //console.log("create step for the story " + props.idStory + " with the user " + props.idUser + " at the steporder :" + props.stepOrder);
        console.log("Create choice for the step ID:" + props.idStep);
        Controller.createChoice(props.idStep)
            .then(id => {
                window.location.reload();
            });
    }

    return (

        <div className={"PlusChoice-Container"}>
            <Button className={"plus-choice-button button"} onClick={createChoice}>
                <Card.Text className={"mb-0"}>
                    <img className={"plus-logo"}
                         src={window.location.origin + '/images/GUI/plus.png'}
                         alt="Logo"
                    />
                </Card.Text>
            </Button>
        </div>

    );
};

export default PlusChoice;
