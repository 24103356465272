import React from 'react';
import "../assets/css/login.css";
import {Container, Form, Button, Card, Alert, Spinner} from "react-bootstrap";
import * as Controller from '../controllers/users_controller';

class LoginForm extends React.Component {


    constructor(props) {
        super(props);
        this.username = React.createRef();
        this.password = React.createRef();
    }

    state = {
        errorVisible: false,
        loading : false,
    };

    _handleKeyDown = (event) => {
        //console.log(event.keyCode);
        switch (event.keyCode) {
            case 13:
                this.login();
                break;
            default:
                break;
        }
    }

    login = () => {
        this.setState({loading:true});
        let account = false;
        Controller.LoginV2(this.username.current.value, this.password.current.value)
            .then(data => {
                account = data
                if (!account) {
                    this.setState({errorVisible: true});
                }
                this.setState({loading:false});
                this.props.onClick(account);
            });
    }

    // componentWillMount deprecated in React 16.3
    componentDidMount() {
        document.addEventListener("keydown", this._handleKeyDown);
    }


    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    render() {

        let spinner;

        if (this.state.loading) {
            spinner =
                <Container style={{justifyContent: 'center', alignItems: 'center', height: '100%', display: 'flex', marginTop: 20}}>
                    <Spinner animation="border" role="status" style={{color: '#E94145'}}>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Container>
        } else
            spinner = null;

        return (
            <Container className="container-login">
                <Card className={"card-login text-center align-middle"}>
                    <Card.Body>
                        <Card.Title className={"mb-4"}><h4>Bienvenue sur Story Flow</h4></Card.Title>
                        <Form>
                            <Form.Group controlId="formBasicUsername">
                                <Form.Control size="lg" type="text" ref={this.username}
                                              placeholder="Nom d'utilisateur"/>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control size="lg" type="password" ref={this.password} placeholder="Mot de passe"/>
                                <Form.Text
                                    className={this.state.errorVisible ? "text-error mt-3" : "text-error, hidden"}>
                                    Nom d'utilisateur ou mot de passe incorrect.
                                </Form.Text>
                            </Form.Group>
                            <Button className="login-button mr-3" variant="danger" onClick={this.login}>Connexion</Button>
                            <Form.Text
                                className={"mt-3 info-login"}>
                                Vous devez avoir un compte créateur pour accéder à ces fonctionnalités.
                            </Form.Text>
                            {spinner}
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default LoginForm;
