import React, {Component} from 'react';
import Table from "react-bootstrap/Table";
import {Accordion, Badge, Card, Spinner, Text} from "react-bootstrap";

import * as Controller from '../../../controllers/promotion_controller';
import Container from "react-bootstrap/Container";


class RequestsCard extends Component {
    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            isLoaded: false,
            requests: [],
            userRequest: '',
        }
    }

    async componentDidMount() {
        Controller.getPromotionRequests(this.state.account.idUser)
            .then(data => {
                this.setState({requests: data, isLoaded: true});
            })
    }

    handleUpdate = async (idRequest, request, idUser, idStatus) => {
        let promotionrequest = JSON.stringify({
            idRequest: idRequest, request: request, idUser: idUser, idStatus: idStatus,
        })

        Controller.updatePromotion(promotionrequest)
            .then(data => {
                Controller.getPromotionRequests(this.state.account.idUser)
                    .then(data => {
                        this.setState({requests: data});
                    })
            })
    }

    render() {
        return (
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                    Demandes <Badge variant="info" style={{alignItems: 'flex-end'}}>{this.state.requests.length}</Badge>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    {this.state.isLoaded === false ? (
                        <Container style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            display: 'flex',
                            marginTop: 20
                        }}>
                            <Spinner animation="border" role="status" style={{color: '#E94145'}}>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Container>
                    ) : (

                        <Card.Body>
                            {this.state.requests.length !== 0 ? (
                                <Table striped bordered hover responsive="md">
                                    <thead>
                                    <tr>
                                        <th>Nom & prénom</th>
                                        <th>E-mail</th>
                                        <th>Justificatif</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.requests.map(request => {
                                        return (
                                            <tr key={request.idRequest}>
                                                <td>{request.lastNameUser} {request.firstNameUser}</td>
                                                <td>{request.mailUser}</td>
                                                <td>{request.request}</td>
                                                <td style={{width: 30}}>
                                                    <img
                                                        style={{width: 15}}
                                                        src={window.location.origin + '/images/GUI/selected.png'}
                                                        alt="Accept Logo"
                                                        onClick={() => this.handleUpdate(request.idRequest, request.request, request.idUser, 1)}
                                                    />
                                                </td>
                                                <td style={{width: 30}}>
                                                    <img
                                                        style={{width: 15}}
                                                        src={window.location.origin + '/images/GUI/cross.png'}
                                                        alt="Delete Logo"
                                                        onClick={() => this.handleUpdate(request.idRequest, request.request, request.idUser, 2)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>) : "Il n'y a actuellement aucune demande de promotion"}
                        </Card.Body>
                    )}
                </Accordion.Collapse>
            </Card>
        );
    }
}

export default RequestsCard;
