import * as config from "../config";

export async function getAllOwnedGroups(idAdmin) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idAdmin", idAdmin);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/group/home');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return [];
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))

    return data;
}

export async function CreateGroup(idUser, group) {
    if (idUser == undefined) {
        return -1;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
        body: group,
    }

    var myRequest = new Request(config.API_URL + '/api/group');

    let data = await (await (fetch(myRequest, myInit)
        .then(res => {
            if (res.ok)
                return res.json();
            else
                return false;
        })
        .catch(err => {
            console.log('Error create group : ', err);
            return false;
        })
    ));

    return data;
}

export async function AddUserToGroup(idUser, idGroup, mail) {
    if (idUser == undefined) {
        return -1;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", idUser);
    myHeaders.append("idGroup", idGroup);
    myHeaders.append("mail", mail.toLowerCase());

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    }

    var myRequest = new Request(config.API_URL + '/api/group/member');

    let data = await (await (fetch(myRequest, myInit)
        .then(res => {
            if (res.ok)
                return res.json();
            else
                return false;
        })
        .catch(err => {
            console.log("Error adding member : ", err);
            return false;
        })
    ));

    return data;
}

export async function DeleteUserToGroup(idUser, idGroup, idAdmin) {
    if (idUser === undefined || idGroup === undefined || idAdmin === undefined) {
        return -1;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", idUser);
    myHeaders.append("idGroup", idGroup);
    myHeaders.append("idAdmin", idAdmin);

    var myInit = {
        method: 'DELETE',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    }

    var myRequest = new Request(config.API_URL + '/api/group/member');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    return false;
            })
            .catch(err => {
                console.log("Error delete member : ", err);
                return false;
            })
    ));

    return data;
}
