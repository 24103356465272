import * as config from '../config';

export async function getChoices(idStep) {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStep", idStep);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/choices');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function createChoice(idStep) {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStep", idStep);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/choice');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function updateChoice(choice) {

    let jsonChoice = JSON.stringify(choice);
    //console.log(jsonIndicator);
    if (choice === undefined || choice === []) {
        return [];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body: jsonChoice,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/choice');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}

export async function updateSetValue(setValue) {

    let jsonSetValue = JSON.stringify(setValue);
    //console.log(jsonIndicator);
    if (setValue === undefined || setValue === []) {
        return [];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body: jsonSetValue,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/setValues');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}