import React, {Component} from 'react';
import Table from "react-bootstrap/Table";
import {Accordion, Card, Pagination, Spinner} from "react-bootstrap";
import {getAllUsers} from "../../../controllers/users_controller";
import SelectRole from "./SelectRole";
import Container from "react-bootstrap/Container";

class UsersCard extends Component {
    constructor(props) {
        super(props);

        var ls = require('local-storage');

        this.state = {
            account: ls('account'),
            modal: false,
            isLoaded: false,
            users: [],
        }
    }

    componentDidMount() {
        this.receivedData();
    }

    receivedData() {
        getAllUsers(this.state.account.idUser)
            .then(data => {
                this.setState({
                    users: data,
                    isLoaded: true
                })
            })
    }

    render() {

        return (
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    Utilisateurs
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    {this.state.isLoaded === false ? (
                        <Container style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            display: 'flex',
                            marginTop: 20
                        }}>
                            <Spinner animation="border" role="status" style={{color: '#E94145'}}>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Container>
                    ) : (
                        <Card.Body>
                            <Table striped bordered hover responsive>
                                <thead>
                                <tr>
                                    <th>Nom et Prenom</th>
                                    <th>Pseudo</th>
                                    <th>E-mail</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.users.map(user => (
                                    <tr key={user.idUser}>
                                        <td>{user.lastNameUser} {user.firstNameUser}</td>
                                        <td>{user.pseudoUser}</td>
                                        <td>{user.mailUser}</td>
                                        <td>
                                            <SelectRole user={user}/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    )}
                </Accordion.Collapse>
            </Card>
        );
    }
}

export default UsersCard;
