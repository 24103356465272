import * as config from '../config';

export async function getIndicators(idStory) {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/indicators');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function updateIndicator(indicator) {

    let jsonIndicator = JSON.stringify(indicator);
    console.log(jsonIndicator);
    if (indicator === undefined || indicator === []) {
        return [];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body: jsonIndicator,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/indicator');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}

export async function createIndicator(idStory, idUser) {
    if (idUser === undefined) {
        return -1;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/indicator/create');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function updateTrigger(trigger) {

    let jsonTrigger = JSON.stringify(trigger);
    //console.log(jsonTrigger);
    if (trigger === undefined || trigger === []) {
        return [];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body: jsonTrigger,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/trigger');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}

export async function createTrigger(idStory, idIndicator) {
    if (idStory === undefined || idIndicator === undefined ) {
        return -1;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idIndicator", idIndicator);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/trigger');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}