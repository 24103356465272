import React from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import '../../assets/css/general.css';
import * as IndicatorsController from '../../controllers/indicators_controller';
import * as StepsController from '../../controllers/steps_controller';


class IndicatorTrigger extends React.Component {

    constructor(props) {
        super(props);

        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            nameIndicator: props.nameIndicator,
            idTrigger: props.triggerData.idTrigger,
            descriptionTrigger: props.triggerData.descriptionTrigger,
            comparator: props.triggerData.comparator,
            idStep: props.triggerData.idStep,
            idIndicator: props.triggerData.idIndicator,
            valueToCheck: props.triggerData.valueToCheck,
            idStory: props.triggerData.idStory,
            allSteps: []
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({nameIndicator: nextProps.nameIndicator});
    }

    componentDidMount(): void {
        StepsController.getSteps(this.state.idStory, this.account.idUser)
            .then(steps => {
                this.setState({allSteps: steps});
            })
    }

    handleChange = event => {
        let {name, value} = event.target;
        if (name === 'idStep')
            value = parseInt(value);

        this.setState({[name]: value}, () => {
            this.updatetrigger();
        })
    }

    handleNumberChange = event => {
        let {name, value, min, max} = event.target;
        value = parseInt(value);
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        this.setState({[name]: value}, () => {
            this.updatetrigger();
        })
    };

    updatetrigger() {
        let trigger_representation = {...this.state};
        delete trigger_representation.allSteps;

        IndicatorsController.updateTrigger(trigger_representation);
        console.log("persist trigger ID:" + this.state.idTrigger);
    }

    render() {
        return (
            <Row>
                <Col>
                    <label className={"trigger-label"}>Si {this.state.nameIndicator}</label>
                </Col>
                <Col>
                    <Form.Control name={"comparator"} as="select" onChange={this.handleChange}
                                  value={this.state.comparator}>
                        <option value={"="}>{"="}</option>
                        <option value={">"}>{">"}</option>
                        <option value={"<"}>{"<"}</option>
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Control name={"valueToCheck"}
                                  type={"number"}
                                  min={-99}
                                  max={99}
                                  value={this.state.valueToCheck}
                                  onChange={this.handleNumberChange}/>
                </Col>
                <Col><label className={"trigger-label"}>aller vers étape</label></Col>
                <Col>
                    <Form.Control name={"idStep"} as="select" onChange={this.handleChange} value={this.state.idStep}>
                        {this.state.allSteps.map((step, key) => (
                            <option key={key} value={step.idStep}>{step.stepOrder + " - " + step.nameStep}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>

        )
    }
}

export default IndicatorTrigger;


