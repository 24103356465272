import * as config from '../config';

export async function getStoriesByUser(idUser) {
    //console.log("Demande histoires de user id : " + idUser);
    if (idUser === undefined) {
        return [];
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/creator/stories');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return [];
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))
    return data;
}

export function getStoryById_old(idStory) {
    const story = {
        idStory: 1,
        nameStory: "Story 1",
        descriptionStory: "Risus feugiat in at aliquam neque. Mattis massa vel, tempor nulla. Eleifend integer purus pulvinar aenean. Feugiat ut porttitor ut aliquam aliquet. Amet, tortor non duis pellentesque ut ullamcorper est. Ipsum gravida nisl scelerisque consequat condimentum. Diam nisi, ipsum imperdiet euismod tellus cras enim. Risus eu eros interdum augue fames eget sapien morbi. Ultrices duis id mattis molestie turpis nisi ut nibh cursus. Turpis risus, aliquet fusce tellus eget ipsum etiam ac.\n" +
            "\n" +
            "Egestas integer at in ipsum. Dictum risus donec dui egestas. Phasellus dui vivamus turpis risus. At ac tellus adipiscing justo, turpis arcu phasellus. Enim parturient vestibulum laoreet odio quisque vivamus. Varius sit cursus fringilla sapien ullamcorper magna suspendisse. Tempus praesent aenean sit tincidunt sed tincidunt. Facilisis nunc facilisi faucibus lorem sit ornare.\n" +
            "\n" +
            "Consectetur libero a quis non pharetra sociis purus. Volutpat montes, pellentesque egestas elementum condimentum amet. Laoreet turpis felis turpis aliquet ac dictum mattis diam. Vitae sit enim pellentesque felis. Mattis mattis tristique integer porttitor adipiscing malesuada a elit. Pretium, blandit quisque morbi tellus, convallis. In non tempus nunc id cursus integer vel. Leo justo elit in magna sit lectus habitant tortor molestie. Felis purus ac eu porta et imperdiet sit. Augue tincidunt tortor non est. Maecenas imperdiet aliquet enim in facilisis. Volutpat amet purus egestas consectetur.",
        dateCreatedStory: "2020-01-11",
        image: "background-story-0931.jpg",
        idUser: 39,
        archived: false,
        groups: [
            {name: "Public", color: "#5CF07D"},
        ],
        themes: [
            {name: "Simulation", color: "#E0E0E0"},
            {name: "Educatif", color: "#E0E0E0"}
        ]
    }

    return story;
}

export async function getStoryById(idStory, idUser) {
    //console.log("Story ID : " + idStory + " - User ID : " + idUser);
    if (idStory === undefined) {
        return [];
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/creator/story');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function updateStory(story) {
    let jsonStory = JSON.stringify(story);
    //console.log(jsonStory);
    if (story === undefined || story === []) {
        return [];
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body : jsonStory,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story');

    let data = await (await (fetch(myRequest, myInit)
        .then(res => {
            if (res.ok)
                return res.json()
            else
                return false;
        })
        .catch(err => {
            console.log('Error: ', err);
            return false;
        })
    ))

    return data;
}

// Kind of delete (logic suppression)
export async function setArchived(idStory, newState, idUser) {
    if (idStory === undefined) {
        return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("state", newState);
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story/archive');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function DeleteStory(idStory, idUser) {
    console.log("Suppression histoire ID:" + idStory);
}

export async function CreateStory(idUser) {
    if (idUser === undefined) {
        return -1;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json();
                else
                    return false;
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export function getAllOwnedGroups(idAdmin) {
    const groups = [
        {
            idGroups: 1,
            nameGroup: "Groupe 1",
            dateCreatedStory: "2020-01-11",
            color: "#FBBB00",
            idUser: 39,
            creatorUsername: "valerian.haury",
            members: [
                {mail: "valerian.haury@etu.hesge.ch"},
                {mail: "valerian.haury@etu.hesge.ch"},
                {mail: "valerian.haury@etu.hesge.ch"},
                {mail: "valerian.haury@etu.hesge.ch"},
                {mail: "valerian.haury@etu.hesge.ch"}
            ]
        },
        {
            idGroups: 2,
            nameGroup: "Groupe 2",
            dateCreatedStory: "2020-02-28",
            color: "#599FD2",
            idUser: 39,
            creatorUsername: "valerian.haury",
            members: [
                {mail: "jeff.muraro@etu.hesge.ch"},
                {mail: "jeff.muraro@etu.hesge.ch"},
                {mail: "jeff.muraro@etu.hesge.ch"},
                {mail: "jeff.muraro@etu.hesge.ch"},
                {mail: "jeff.muraro@etu.hesge.ch"}
            ]
        }
    ];

    return groups
}

export async function getAllThemes() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/themes');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return [];
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))
    return data;
}

export async function getAllGroupsFromCreator(idUser) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idUser", idUser);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/groups');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return [];
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))

    return data;
}

export async function addGroup(idStory, idUser, idGroup) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);
    myHeaders.append("idGroup", idGroup);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story/group');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return res;
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))

    return data
}

export async function removeGroup(idStory, idUser, idGroup) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);
    myHeaders.append("idGroup", idGroup);

    var myInit = {
        method: 'DELETE',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story/group');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return res;
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))

    return data;
}

export async function addTheme(idStory, idUser, idTheme) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);
    myHeaders.append("idTheme", idTheme);

    var myInit = {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story/theme');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return res;
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))

    return data;
}

export async function removeTheme(idStory, idUser, idTheme) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idStory", idStory);
    myHeaders.append("idUser", idUser);
    myHeaders.append("idTheme", idTheme);

    var myInit = {
        method: 'DELETE',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/story/theme');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else
                    return res;
            })
            .catch(err => {
                console.log('Error: ', err);
                return [];
            })
    ))

    return data;
}
