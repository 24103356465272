import React from 'react';
import '../../assets/css/storyconnections.css';
import * as StepsController from '../../controllers/steps_controller';
import * as ConnectionsController from '../../controllers/connections_controller';
import {Alert, Button, Col, Form, InputGroup, ListGroup, Row} from "react-bootstrap";
import ConnectionsCross from "./ConnectionsCross";


class StoryChoiceConnections extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            descriptionChoice: props.choiceData.descriptionChoice,
            idChoice: props.choiceData.idChoice,
            idStep: props.choiceData.idStep,
            nameChoice: props.choiceData.nameChoice,
            stepchoices: props.choiceData.stepchoices,
            allSteps: [],
            idStory: props.idStory,
            apparitionChanceError: false,
            negativeApparitionChance: false,
            stepChoiceSaved: false
        }
    }

    componentDidMount(): void {
        StepsController.getSteps(this.state.idStory, this.account.idUser)
            .then(steps => {
                this.setState({allSteps: steps});
            })
    }

    addNewLink = () => {
        console.log("Ajout d'un nouveau lien sur le choix : " + this.state.idChoice + " dans la step : " + this.state.idStep);
        let apparitionChance = this.state.stepchoices.length === 0 ? 100 : 0;
        ConnectionsController.createStepChoice(this.state.idChoice, this.state.idStep, apparitionChance)
            .then(id => {
                window.location.reload();
            });
    }

    handleChange = event => {
        let {name, value, min, max} = event.target;
        //get the stepchoice index and the stepchoice property to modify
        let splitedValue = name.split("-");
        let index = splitedValue[0];
        name = splitedValue[1];
        value = parseInt(value);
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        let stepchoices_representation = [this.state.stepchoices][0];
        stepchoices_representation[index][name] = value;
        this.setState({stepchoices: stepchoices_representation});
    }

    handleNextStepChange = event => {
        let {name, value} = event.target;
        let splitedValue = name.split("-");
        let index = splitedValue[0];
        name = splitedValue[1];
        value = parseInt(value);
        let stepchoices_representation = [this.state.stepchoices][0];
        stepchoices_representation[index][name] = value;
        //console.log(stepchoices_representation);
        this.setState({stepchoices: stepchoices_representation});
    }

    saveStepChoices = () => {
        //console.log("save stepchoices");
        const add = (a, b) => a + b;
        //get all the apparition chances
        let apparitionChances = this.state.stepchoices.map(stepchoice => stepchoice.apparitionChance);
        let negativeChance = false;
        apparitionChances.map(apparitionChance => {
            if (apparitionChance < 0) {
                negativeChance = true;
            }
        });
        //console.log(apparitionChances);
        // sum of all the apparition chance
        let sumChances = apparitionChances.reduce(add);
        //console.log(sumChances);

        // they have to be equal to 100%
        if (sumChances !== 100) {
            this.setState({apparitionChanceError: true});
            this.setState({negativeApparitionChance: false});
            this.setState({stepChoiceSaved: false});
        } else if (negativeChance) {
            this.setState({negativeApparitionChance: true});
            this.setState({apparitionChanceError: false});
            this.setState({stepChoiceSaved: false});
        } else {
            this.state.stepchoices.map((stepChoice, key) => {
                ConnectionsController.updateStepChoice(stepChoice)
                    .then(() => {
                        if (key === this.state.stepchoices.length - 1) {
                            //window.location = '/story/' + this.state.idStory + '/connections/';
                            this.setState({apparitionChanceError: false});
                            this.setState({negativeApparitionChance: false});
                            this.setState({stepChoiceSaved: true});
                        }
                    })
                return "done";
            });
        }
    }

    render() {

        const stepchoices = this.state.stepchoices;
        let noLink;
        let apparitionChanceAlert;
        let negativeChanceAlert;
        let stepChoiceSavedAlert;

        if (stepchoices.length === 0) {
            noLink = <div>Le choix <b>{this.state.nameChoice}</b> n'est lié à aucune étape</div>;
        } else {
            noLink = null;
        }

        if (this.state.apparitionChanceError) {
            apparitionChanceAlert =
                <Alert key={"lol"} variant={"danger"} className={"mt-3"}>
                    La valeur total des chances d'apparition doit être égale à 100%.
                </Alert>
        } else
            apparitionChanceAlert = null;

        if (this.state.negativeApparitionChance) {
            negativeChanceAlert =
                <Alert key={"lol"} variant={"danger"} className={"mt-3"}>
                    Les chances d'apparition ne doivent pas être négative.
                </Alert>
        } else
            negativeChanceAlert = null;

        if (this.state.stepChoiceSaved) {
            stepChoiceSavedAlert =
                <Alert key={"lol"} variant={"success"} className={"mt-3"}>
                    La lien a été sauvegardé.
                </Alert>
        } else
            stepChoiceSavedAlert = null;


        return (
            <ListGroup.Item className={"trigger-item"}>
                {noLink}
                {this.state.stepchoices.map((linkedStep, key) => (
                    <Row key={key}>
                        <Col>
                            <label className={"trigger-label"}>{this.state.nameChoice}</label>
                        </Col>
                        <Col className={"arrow-text"}>
                            <label>{"→"}</label>
                        </Col>
                        <Col>
                            <Form.Control name={key + "-idNextStep"} as="select" value={linkedStep.idNextStep}
                                          onChange={this.handleNextStepChange}>
                                {this.state.allSteps.map((step, key) => (
                                    <option key={key}
                                            value={step.idStep}>{step.stepOrder + " - " + step.nameStep}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col>
                            <InputGroup>
                                <Form.Control
                                    name={key + "-apparitionChance"}
                                    type="number"
                                    placeholder="chance d'apparition"
                                    min={0}
                                    max={100}
                                    required
                                    value={linkedStep.apparitionChance}
                                    onChange={this.handleChange}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                        <ConnectionsCross idStepChoice={linkedStep.idStepChoice} idUser={this.account.idUser}/>
                    </Row>
                ))}
                <Row>
                    <Button size={"sm"} className={"choice-connection-add-button"} variant="success"
                            onClick={this.addNewLink}>
                        Ajouter un lien
                    </Button>
                    <Button size={"sm"} className={"choice-connection-save-button"}
                            variant="danger"
                            onClick={this.saveStepChoices}
                            disabled={(this.state.stepchoices.length === 0 || this.state.stepchoices.length >= 100) ? "disabled" : ""}
                    >Sauvegarder</Button>
                </Row>
                {apparitionChanceAlert}
                {negativeChanceAlert}
                {stepChoiceSavedAlert}
            </ListGroup.Item>
        )
    }
}

export default StoryChoiceConnections;
