import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from "./components/Dashboard";
import TopNavigation from "./components/TopNavigation";
import LoginForm from "./components/LoginForm";
import StoryGeneral from "./components/storyComponents/StoryGeneral";
import StoryIndicators from "./components/storyComponents/StoryIndicators";
import StoryObjects from "./components/storyComponents/StoryObjects";
import StorySteps from "./components/storyComponents/StorySteps";
import StoryConnections from "./components/storyComponents/StoryConnections";
import Groups from "./components/groups/Groups";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    HashRouter,
} from "react-router-dom";
import StoryStep from "./components/storyComponents/StoryStep";
import SuperAdmin from './components/superAdmin';

class App extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.state = {account:ls("account")}
    }

    handleLoginClick(e) {
        var ls = require('local-storage');
        ls("account",e);
        this.setState({account:e});
    }

    handleLogOutClick() {
        var ls = require('local-storage');
        ls("account",false);
        this.setState({account:false});
    }

    render() {
        if(!this.state.account) {
            return (
                <Router>
                    <LoginForm onClick={(e)=> {
                        this.handleLoginClick(e)
                    }} />
                </Router>
            );
        } else {
            //console.log(this.state.account);
            return (
                <HashRouter basename='/'>
                    <div className="App">
                        <TopNavigation onClick={(e)=> {
                            this.handleLogOutClick()
                        }} />
                    </div>
                    <Switch>
                        <Route path="/administration" render={() => (
                            this.state.account.roleIdRole === 3 ? (
                                <SuperAdmin />
                            ) : (
                                <Redirect to="/" />
                            )
                        )}/>
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/groups" component={Groups} />
                        <Route path="/story/:idStory/step/:idStep/:activeTab" component={StoryStep} />
                        <Route path="/story/:idStory/general" component={StoryGeneral} />
                        <Route path="/story/:idStory/indicators" component={StoryIndicators} />
                        <Route path="/story/:idStory/objects" component={StoryObjects} />
                        <Route path="/story/:idStory/steps" component={StorySteps} />
                        <Route path="/story/:idStory/connections/:stepOrder" component={StoryConnections} />
                        <Route path="/" component={Dashboard} />
                    </Switch>
                </HashRouter>
            );
        }
    }
}

export default App;
