import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import '../../assets/css/general.css';
import '../../assets/css/storynav.css';
import {
    Link
} from "react-router-dom";
import * as Controller from "../../controllers/stories_controller";


class StoryNavigation extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.state = {
            idStory: props.idStory,
            idUser: props.idUser,
            nameStory: ""
        }
    }

    componentDidMount(): void {
        //console.log("idStory : " + this.props.idStory);
        Controller.getStoryById(this.state.idStory, this.state.idUser)
            .then(storyData => {
                this.setState({
                    nameStory: storyData.nameStory,
                });
            });
    }


    render() {
        return (

            <Navbar className={"navbar-story"} expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav-story">
                        <Link to="/" className={"story-nav-link"}>
                            <img className={"story-back-icon"}
                                 src={window.location.origin + '/images/GUI/left_arrow.svg'}
                                 alt="Logo"
                            />
                        </Link>
                        <span className={"story-title"}>{this.state.nameStory}</span>
                        <Link className={this.props.generalTab ? "story-nav-link active" : "story-nav-link"}
                              to={"/story/" + this.state.idStory + "/general"}>Général</Link>
                        <Link className={this.props.indicatorsTab ? "story-nav-link active" : "story-nav-link"}
                              to={"/story/" + this.state.idStory + "/indicators"}>Indicateurs</Link>
                        {/*<Link className={this.props.objectsTab ? "story-nav-link active" : "story-nav-link"}
                              to={"/story/" + this.state.idStory + "/objects"}>Objets</Link>*/}
                        <Link className={this.props.stepsTab ? "story-nav-link active" : "story-nav-link"}
                              to={"/story/" + this.state.idStory + "/steps"}>Étapes</Link>
                        <Link className={this.props.connectionsTab ? "story-nav-link active" : "story-nav-link"}
                              to={"/story/" + this.state.idStory + "/connections/0"}>Liaison</Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default StoryNavigation;
