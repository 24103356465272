import sha1 from "sha1";
import * as config from '../config';

export async function LoginV1(username, password) {
    return true
}


export async function LoginV2(username, password) {

    var encryptedPassword = sha1(sha1('story-' + password) + '-flow');
    //console.log(encryptedPassword);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("pseudoUser", username);
    myHeaders.append("pwdUser", encryptedPassword);

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/creator/login');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))
    return data;
}

export async function getAllUsers(idAdmin){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("idAdmin", idAdmin)

    var myInit = {
        method: 'GET',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default'
    };

    var myRequest = new Request(config.API_URL + '/api/Users');

    let data = await (await (fetch(myRequest, myInit)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}

export async function updateUser(user) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var myInit = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
            idUser: user.idUser,
            lastNameUser: user.lastNameUser,
            firstNameUser: user.firstNameUser,
            pseudoUser: user.pseudoUser,
            mailUser: user.mailUser,
            pwdUser: user.pwdUser,
            dateCreatedUser: user.dateCreatedUser,
            roleIdRole: user.roleIdRole,
        }),
        mode: 'cors',
        cache: 'default',
    };

    var myRequest = new Request(config.API_URL + '/api/user/edit', myInit);
    let data = await (await (fetch(myRequest)
            .then(res => {
                if (res.ok)
                    return res.json()
                else {
                    console.log(res);
                    return false;
                }
            })
            .catch(err => {
                console.log('Error: ', err);
                return false;
            })
    ))

    return data;
}
