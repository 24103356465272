import React from 'react';
import * as Controller from '../../controllers/steps_controller';
import '../../assets/css/storysteps.css';
import StoryNavigation from "./StoryNavigation";
import {Container, Accordion, Button, Card, InputGroup, Col, Row} from "react-bootstrap";
import PlusStep from "./PlusStep";
import {Link} from "react-router-dom";

class StorySteps extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        //console.log(props.match.params);
        this.state = {
            idStory:props.match.params.idStory,
            idUser: this.account.idUser,
            steps: []
        }
    }

    componentDidMount(): void {
        Controller.getSteps(this.state.idStory, this.state.idUser)
            .then(steps => {
                this.setState({steps: steps});
            })
    }

    render() {
        return (
            <>
                <StoryNavigation key={this.state.idUser} stepsTab idStory={this.state.idStory} idUser={this.state.idUser}/>
                <Container className="container-storycard">
                    <h3 className={"mt-3"}>Liste des étapes</h3>
                    <Row className={"ml-2"}>
                        <Col className={"mt-4"}>
                            <Accordion className={"steps"}>
                                {this.state.steps.map((step, key) => (
                                    <Card key={key}>
                                        <Accordion.Toggle as={Card.Header} eventKey={this.state.idIndicator}>
                                            <InputGroup>
                                                {/*<IndicatorCross RedirectURL={"/archive/story/"}
                                                                elementId={step.idStep} title={"Supprimer étape"}
                                                                text={"Voulez-vous vraiment supprimer cette étape ?"/>*/}
                                                <label className={"step-order-label"}><b>{step.stepOrder} - </b></label>
                                                <label className={"step-label"}
                                                       htmlFor="basic-url">{step.nameStep}
                                                </label>
                                                <Link   to={"/story/" + this.state.idStory + "/step/" + step.idStep + "/general"}>
                                                    <Button className={"step-modification-button"}
                                                            variant="danger">
                                                        Modifier
                                                    </Button>
                                                </Link>
                                            </InputGroup>
                                        </Accordion.Toggle>
                                    </Card>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                    <Col className={"plusStep-col"}>
                        <PlusStep idStory={this.state.idStory} idUser={this.state.idUser} stepOrder={this.state.steps.length + 1}/>
                    </Col>

                </Container>
            </>
        )
    }
}

export default StorySteps;
