import React, {Component} from 'react';
import DirectCross from "./DirectCross";

class GroupMember extends Component {
    render() {
        return (
            <tr>
                <td>
                    <div style={{float: "left"}}>{this.props.data.mailUser}</div>
                    <DirectCross data={this.props.data} group={this.props.group}/>
                </td>
            </tr>
        );
    }
}

export default GroupMember;
