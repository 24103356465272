import React from 'react';
import {Badge} from 'react-bootstrap';
import '../assets/css/general.css';
import '../assets/css/storycard.css';

class StoryTagBadge extends React.Component {

    constructor(props) {
        super(props);
        this.title = props.title;
        this.color = props.color
    }
    render() {
        return (
            <Badge className={"tagBadge"} style={{backgroundColor: this.color}} >{this.title}</Badge>
        )
    }
}

export default StoryTagBadge;
