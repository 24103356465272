import React from 'react';
import * as Controller from '../controllers/stories_controller';
import StoryCard from './StoryCard';
import '../assets/css/dashboard.css';
import {Col, Row, Container, Spinner} from "react-bootstrap";
import PlusStory from "./PlusStory";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.state = {
            account: ls("account"),
            isLoaded: false,
            stories: []
        }
    }

    async componentDidMount(): void {

        Controller.getStoriesByUser(this.state.account.idUser)
            .then(data => {
                this.setState({stories: data, isLoaded: true});
                //console.log(data);
            });
    }

    render() {
        if (this.state.isLoaded === false) {
            return (
                <Container style={{justifyContent: 'center', alignItems: 'center', height: '100%', display: 'flex', marginTop: 20}}>
                    <Spinner animation="border" role="status" style={{color: '#E94145'}}>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Container>
            )
        } else {
            return (
                <Container className="container-storycard">
                    <Row>
                        {this.state.stories.map((story) => (
                            <Col key={story.idStory} md={"auto"} className={"mt-4"}><StoryCard key={story.idStory}
                                                                                               className="storycard"
                                                                                               storydata={story}/></Col>
                        ))}
                        <Col key={"newStory-col"} md={"auto"} className={"newStory-col mt-4"}><PlusStory idUser={this.state.account.idUser}/></Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default Dashboard;
