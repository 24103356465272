import React from 'react';
import * as Controller from '../../controllers/stories_controller';
import '../../assets/css/storygeneral.css';
import StoryNavigation from "./StoryNavigation";
import {Container, Form, Col, Row, Card, ListGroup, InputGroup, Button} from "react-bootstrap";
import Moment from 'moment';

class StoryGeneral extends React.Component {

    constructor(props) {
        super(props);
        var ls = require('local-storage');
        this.account = ls("account");
        this.DEFAULT_STORY_NAME = "Histoire non nommée";
        this.DEFAULT_STORY_DESCRIPTION = "Description par défaut";
        this.state = {
            idStory: props.match.params.idStory,
            nameStory: "",
            descriptionStory: "",
            dateCreatedStory: "",
            idUser: this.account.idUser,
            visible: null,
            storyImage: "",
            groups: [],
            themes: [],
            allThemes: [],
            allGroups: []
        }
    }

    componentDidMount(): void {
        Controller.getAllThemes()
            .then(themes => {
                this.setState({allThemes: themes})
            })

        Controller.getAllGroupsFromCreator(this.state.idUser)
            .then(groups => {
                this.setState({allGroups: groups})
            })

            Controller.getStoryById(this.state.idStory, this.state.idUser)
                .then(storyData => {
                    //console.log("idStory : " + this.state.idStory + " - idUser : " + this.state.idUser)
                    this.setState({
                        idStory: storyData.idStory,
                        nameStory: storyData.nameStory,
                        descriptionStory: storyData.descriptionStory,
                        dateCreatedStory: storyData.dateCreatedStory,
                        idUser: storyData.idUser,
                        visible: storyData.visible,
                        storyImage: storyData.storyImage,
                        archived: storyData.archived,
                        groups: storyData.groups,
                        themes: storyData.themes
                    });
                });
    }

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    }

    handleCheckBox = event => {
        let newState = 1 - this.state.visible;
        this.setState({visible: newState})
    }

    isThemeActive(theme) {
        let result = false;
        if (this.state.themes !== undefined) {
            this.state.themes.forEach(activeTheme => {
                if (activeTheme.idTheme === theme.idTheme) {
                    result = true;
                }
            });
        }
        return result;
    }

    isGroupActive(group) {
        let result = false;
        if (this.state.groups !== undefined) {
            this.state.groups.forEach(activeGroup => {
                if (activeGroup.idGroup === group.idGroup) {
                    result = true;
                }
            });
        }
        return result;
    }

    handleThemeClick(theme) {
        console.log(theme);
        let newActiveThemes = this.state.themes;
        let matchingTheme = null;
        if (this.isThemeActive(theme)) {
            let index = -1;
            for (var i = 0; i < newActiveThemes.length; i++) {
                // look for the entry with a matching `code` value
                if (newActiveThemes[i].idTheme === theme.idTheme) {
                    index = i;
                    matchingTheme = newActiveThemes[i];
                    // we found it
                    // obj[i].name is the matched result
                }
            }
            if (index > -1) {
                newActiveThemes.splice(index, 1);
                // Suppresion du theme dans la bdd
                Controller.removeTheme(this.state.idStory, this.state.idUser, matchingTheme.idTheme);
            }

        } else {
            newActiveThemes.push(theme);
            // Ajout du theme dans la bdd
            Controller.addTheme(this.state.idStory, this.state.idUser, theme.idTheme);
        }

        this.setState({themes: newActiveThemes});
    }

    handleGroupClick(group) {
        let newActiveGroups = this.state.groups;
        let matchingGroup = null;
        if (this.isGroupActive(group)) {
            let index = -1;
            for (var i = 0; i < newActiveGroups.length; i++) {
                // look for the entry with a matching `code` value
                if (newActiveGroups[i].idGroup === group.idGroup) {
                    index = i;
                    // we found it
                    // obj[i].name is the matched result
                    matchingGroup = newActiveGroups[i];
                }
            }

            if (index > -1) {
                newActiveGroups.splice(index, 1);
                // Suppresion du groupes dans la bdd
                Controller.removeGroup(this.state.idStory, this.state.idUser, matchingGroup.idGroup);
            }
        } else {
            newActiveGroups.push(group);
            // Ajout du groupes dans la bdd
            Controller.addGroup(this.state.idStory, this.state.idUser, group.idGroup);
        }

        this.setState({groups: newActiveGroups});
    }

    save = () => {
        let copyStory =  {...this.state};
        delete copyStory.allGroups;
        delete copyStory.allThemes;
        delete copyStory.themes;
        delete copyStory.groups;
        if (copyStory.nameStory === "")
            copyStory.nameStory = this.DEFAULT_STORY_NAME;

        Controller.updateStory(copyStory)
            .then(response => {
               window.location.reload();
            });
    }

    render() {
        return (
            <>
                <StoryNavigation key={this.state.idUser} generalTab idStory={this.state.idStory} idUser={this.state.idUser}/>
                <Container className="container-storycard">
                    <h3 className={"mt-3"}>Informations générales</h3>
                    <Row className={"ml-2"}>
                        <Col key={"nameStory"} md={"auto"} className={"story-title-col mt-4 mb-3"}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Titre</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    name={"nameStory"}
                                    type="text"
                                    value={this.state.nameStory == this.DEFAULT_STORY_NAME ? "" : this.state.nameStory}
                                    placeholder={this.DEFAULT_STORY_NAME}
                                    onChange={this.handleChange}
                                    maxLength="80"
                                />
                                <span className={"creation-date"}>Crée le {Moment(this.state.dateCreatedStory).format('DD.MM.YYYY')}</span>
                            </InputGroup>
                            <br/>
                            <Form.Group controlId="visibleCheckBox">
                                <Form.Check type="checkbox" label="Invisible aux lecteurs"
                                            checked={this.state.visible ? "" : "checked"}
                                            onChange={this.handleCheckBox}/>
                            </Form.Group>
                        </Col>

                        <Col key={"descriptionStory"} md={"auto"} className={"story-synopsis-col mt-3"}>
                            <Form.Label>Synopsis</Form.Label>
                            <Form.Group className="story-synopsis mb-3">
                                <Form.Control
                                    name={"descriptionStory"}
                                    as="textarea"
                                    rows="15"
                                    value={this.state.descriptionStory == this.DEFAULT_STORY_DESCRIPTION ? "" : this.state.descriptionStory}
                                    placeholder={this.DEFAULT_STORY_DESCRIPTION}
                                    onChange={this.handleChange}
                                    maxLength="1000"
                                />
                            </Form.Group>
                        </Col>

                        <Col key={"ThemesList"} md={"auto"} className={"groups-themes-col mt-3 mb-3"}>
                            <Card style={{width: '18rem'}} className={"groups-themes-card mr-4 mb-4"}>
                                <Card.Header>Thèmes</Card.Header>
                                <ListGroup variant="flush">
                                    {this.state.allThemes.map((theme, key) => (
                                        <ListGroup.Item
                                            className={this.isThemeActive(theme) ? "selected" : ""}
                                            key={"theme-" + key}
                                            onClick={() => {
                                                this.handleThemeClick(theme)
                                            }}
                                        >
                                            {theme.nameTheme}
                                            <img className={"selected-logo"}
                                                 src={window.location.origin + '/images/GUI/selected.png'}
                                                 alt="selected-logo"
                                                 style={{display: this.isThemeActive(theme) ? '' : 'none'}}
                                            />
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card>

                            <Card style={{width: '18rem'}} className={"groups-themes-card"}>
                                <Card.Header>Groupes</Card.Header>
                                <ListGroup className={"pb-1"} variant="flush">
                                    {this.state.allGroups.map((group, key) => (

                                        <ListGroup.Item style={{backgroundColor: group.colorGroup}}
                                                        className={this.isGroupActive(group) ? "group-item selected" : "group-item"}
                                                        key={"group-" + key}
                                                        onClick={() => {
                                                            this.handleGroupClick(group)
                                                        }}
                                        >
                                            {group.nameGroup}
                                            <img className={"selected-logo"}
                                                 src={window.location.origin + '/images/GUI/selected.png'}
                                                 alt="selected-logo"
                                                 style={{display: this.isGroupActive(group) ? '' : 'none'}}
                                            />
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col key={"SaveButton"} className={"save-button-col mt-3 mb-3"}>
                            <Button className={"save-button"} variant="danger" size="lg" onClick={this.save}>Sauvegarder</Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default StoryGeneral;
